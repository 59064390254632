import "vant/es/button/style";
import _Button from "vant/es/button";
export default {
  name: 'login',
  components: {
    [_Button.name]: _Button
  },
  data() {
    return {
      progress: 2,
      buttonLoad: false,
      buttonLoadtwo: false,
      formData: {
        cardNo: '',
        cardPwd: ''
      },
      user: {},
      token: '',
      int: undefined,
      time: 0,
      getcodeLoad: false
    };
  },
  async created() {
    let nowTime = parseInt(new Date().getTime() / 1000);
    let setCodeTime = localStorage.getItem('setCodeTime') ? parseInt(localStorage.getItem('setCodeTime')) : 0;
    if (setCodeTime + 60 - nowTime > 0) {
      this.setTime(setCodeTime + 60 - nowTime);
    }
    let aa = '/public/ticket/20231117162218-2.jpg';
    console.log(aa.split('public/ticket/')[1]);
  },
  methods: {
    async getCode() {
      if (!this.formData.phone) {
        this.$toast({
          message: '请输入手机号码'
        });
        return false;
      }
      if (this.formData.phone.length != 11) {
        this.$toast({
          message: '请输入11位手机号码'
        });
        return false;
      }
      if (this.getcodeLoad) return false;
      this.getcodeLoad = true;
      let result = await this.$request({
        method: 'get',
        url: '/app/auth/login/phone',
        params: {
          phone: this.formData.phone
        }
      });
      this.getcodeLoad = false;
      if (result.code == 0) {
        this.$toast({
          message: '发送成功'
        });
        this.setTime(60);
      } else {
        this.$toast({
          message: '发送失败'
        });
      }
    },
    //检验手机号码
    async checkPhone() {
      if (!this.formData.phone) {
        this.$toast({
          message: '请输入手机号码'
        });
        return false;
      }
      if (!this.formData.code) {
        this.$toast({
          message: '请输入验证码'
        });
        return false;
      }
      this.buttonLoad = true;
      let result = await this.$request({
        method: 'post',
        url: '/app/auth/login/oauth',
        data: {
          phone: this.formData.phone,
          code: this.formData.code
        }
      });
      this.buttonLoad = false;
      if (result.code == 0) {
        this.$toast({
          message: '登录成功'
        });
        // this.$store.commit('setUserInfo',result.data.user)
        // this.$store.commit('setAccessToken',result.data.token)
        // let path=this.$route.query.referrer
        // this.$router.replace(path?path:'/index')

        this.user = result.data.user;
        this.token = result.data.token;
        this.progress = 2;
      } else {
        this.$toast({
          message: result.message
        });
      }
    },
    async loginClick() {
      if (!this.formData.cardNo) {
        this.$toast({
          message: '请输入卡号'
        });
        return false;
      }
      if (!this.formData.cardPwd) {
        this.$toast({
          message: '请输入卡密'
        });
        return false;
      }
      this.buttonLoadtwo = true;
      let result = await this.$request({
        method: 'post',
        url: '/app/auth/login/card',
        headers: {
          // token:this.token
        },
        data: this.formData
      });
      console.log(result);
      this.buttonLoadtwo = false;
      if (result.code == 0) {
        this.$toast({
          message: '验证成功'
        });
        // this.$store.commit('setUserInfo',{
        //     phone:this.formData.phone,
        //     name:this.formData.name,
        //     token:result.data
        // })

        this.$store.commit('setUserInfo', result.data.user);
        this.$store.commit('setAccessToken', result.data.token);
        let path = this.$route.query.referrer;
        // this.$router.replace(path?path:'/index')
        this.$router.replace('/index');
      } else {
        this.$toast({
          message: result.message
        });
      }
    },
    setTime(time) {
      let that = this;
      that.time = time;
      clearInterval(that.int);
      if (time == 60) {
        localStorage.setItem('setCodeTime', parseInt(new Date().getTime() / 1000));
      }
      that.int = setInterval(function () {
        that.time--;
        if (that.time <= 0) {
          clearInterval(that.int);
        }
      }, 1000);
    }
  }
};