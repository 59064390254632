var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "logo"
  }), _c('div', {
    staticClass: "logo-label"
  }, [_vm._v("礼品卡兑换")]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/card_number.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.cardNo,
      expression: "formData.cardNo"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "卡号"
    },
    domProps: {
      "value": _vm.formData.cardNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "cardNo", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "item"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/card_password.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.cardPwd,
      expression: "formData.cardPwd"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "placeholder": "卡密"
    },
    domProps: {
      "value": _vm.formData.cardPwd
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "cardPwd", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "sub-button"
  }, [_c('van-button', {
    attrs: {
      "color": "#C70C0C",
      "loading": _vm.buttonLoadtwo,
      "loading-size": "24px",
      "loading-text": "加载中...",
      "size": "large",
      "block": ""
    },
    on: {
      "click": _vm.loginClick
    }
  }, [_vm._v("查看礼品 ")])], 1)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };